.slidess {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    opacity: 1;
    visibility: inherit;
    z-index: 20;
    /* Se muestra un color de fondo mientras se está cargando la imagen de fondo o si hay problemas para cargarla */
    background-color: #66999;
}